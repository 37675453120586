import React from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Home from "./pages/Home";
import Dashboard from "./pages/Dashboard";
import ServerSettings from "./pages/ServerSettings";
import { ChakraProvider, Box, Flex, Image, Text, Button } from "@chakra-ui/react";

function NotFound() {
    const returnHome = () => {
        window.location.href = "/";
    };
    return (
        <Box textAlign="center" p={4}>
            <Text fontSize="2xl" fontWeight="bold" color="red.500">
                404 - Page Not Found
            </Text>
            <Button colorScheme="teal" size="lg" onClick={returnHome}>
                Go back to Home
            </Button>
        </Box>
    );
}

function App() {
    return (
        <ChakraProvider>
            <Router>
                <Flex direction="column" height="100vh">
                    <Box as="header" bg="teal.500" p={4}>
                        <Flex align="center">
                            <Link
                                to="/"
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    textDecoration: "none"
                                }}
                            >
                                <Image src="/favicon.ico" alt="Logo" boxSize="32px" mr={2} />
                                <Text color="white" fontWeight="bold" fontSize="18px">
                                    イベラン専用
                                </Text>
                            </Link>
                        </Flex>
                    </Box>
                    <Box flex="1">
                        <Routes>
                            <Route path="/" element={<Home />} />
                            <Route path="/dashboard" element={<Dashboard />} />
                            <Route path="/server/:serverId/general" element={<ServerSettings />} />
                            <Route path="*" element={<NotFound />} />
                        </Routes>
                    </Box>
                </Flex>
            </Router>
        </ChakraProvider>
    );
}

export default App;
