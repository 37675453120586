import React, { useState, useEffect } from "react";
import { Box, Heading, Textarea, Button, FormControl, FormLabel, Input, useToast } from "@chakra-ui/react";
import { useParams } from "react-router-dom";

function ServerSettings() {
    const { serverId } = useParams();
    const [voicevoxText, setVoicevoxText] = useState("");
    const [audioFile, setAudioFile] = useState(null);
    const toast = useToast();

    useEffect(() => {
        fetch(`https://alstromeria.net/api/server/${serverId}/settings`)
            .then(res => res.json())
            .then(data => setVoicevoxText(data.voicevoxText))
            .catch(error => {
                console.error("Failed to fetch server settings:", error);
            });
    }, [serverId]);

    const handleSave = () => {
        const formData = new FormData();
        if (audioFile) {
            formData.append("audioFile", audioFile);
        }

        fetch(`https://alstromeria.net/api/server/${serverId}/settings`, {
            credentials: "include",
            method: "POST",
            body: formData
        })
            .then(() => {
                toast({
                    title: "Settings saved.",
                    description: "Your settings have been saved successfully.",
                    status: "success",
                    duration: 5000,
                    isClosable: true
                });
            })
            .catch(error => {
                console.error("Failed to save settings:", error);
                toast({
                    title: "Error.",
                    description: "Failed to save settings.",
                    status: "error",
                    duration: 5000,
                    isClosable: true
                });
            });
    };

    return (
        <Box p={5} maxW="800px" mx="auto" mt={10} boxShadow="md" borderRadius="md" bg="white">
            <Heading mb={5}>Server Settings</Heading>
            <FormControl id="voicevoxText" mb={5}>
                <FormLabel>VoiceVox Text</FormLabel>
                <Textarea
                    value={voicevoxText}
                    onChange={e => setVoicevoxText(e.target.value)}
                    placeholder="Enter the text for VoiceVox to read when the bot joins the voice channel."
                    size="lg"
                />
            </FormControl>
            <FormControl id="audioFile" mb={5}>
                <FormLabel>Upload Audio File</FormLabel>
                <Input type="file" accept="audio/mp3" onChange={e => setAudioFile(e.target.files[0])} />
            </FormControl>
            <Button colorScheme="teal" size="lg" onClick={handleSave}>
                Save Settings
            </Button>
        </Box>
    );
}

export default ServerSettings;
