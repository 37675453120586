import React, { useState, useEffect } from "react";
import { Box, Heading, Image, Tooltip, Flex } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

function Dashboard() {
    const [guilds, setGuilds] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        fetch("https://alstromeria.net/api/user-guilds", {
            credentials: "include"
        })
            .then(res => res.json())
            .then(data => setGuilds(data))
            .catch(error => console.error("Error fetching user guilds:", error));
    }, []);

    const handleServerClick = serverId => {
        navigate(`/server/${serverId}/general`);
    };

    return (
        <Box p={5} maxW="800px" mx="auto" mt={10} boxShadow="md" borderRadius="md" bg="white">
            <Heading mb={5} textAlign="center">
                Servers
            </Heading>
            <Flex justifyContent="center" wrap="wrap" gap={4}>
                {guilds.map(guild => (
                    <Tooltip key={guild.id} label={guild.name} fontSize="md">
                        <Image
                            src={
                                guild.icon
                                    ? `https://cdn.discordapp.com/icons/${guild.id}/${guild.icon}.png`
                                    : `https://alstromeria.net/api/guild-image/${encodeURIComponent(guild.name)}`
                            }
                            alt={guild.name}
                            boxSize="80px"
                            borderRadius="full"
                            _hover={{ transform: "scale(1.1)", transition: "0.2s" }}
                            cursor="pointer"
                            onClick={() => handleServerClick(guild.id)}
                        />
                    </Tooltip>
                ))}
            </Flex>
        </Box>
    );
}

export default Dashboard;
